import React, { FC, useState, useCallback, useEffect } from "react";
import { TestPromptDTO, PromptVersionDTO } from "@dasha.ai/sdk/web/rest-api/generated/testsystem";
import ReactDiffViewer from "react-diff-viewer";

import "./index.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Checkbox,
  Input,
  List,
  ListItem,
  Loader,
  Message,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TabPane,
  TextArea,
} from "semantic-ui-react";
import CopyButtonAction from "../uikit/CopyButtonAction";
import { ActionButton, Icon } from "../uikit";
import { GptOptionsTable } from "../uikit/gpt/Options/gptOptions";
import { FunctionEditorCard } from "../uikit/gpt/Functions/functionEditor";
import { Prompt } from "../core/api/prompts/PromptStore";
import { useStore } from "../core/api/GlobalStoreContext";
import { observer } from "mobx-react-lite";
import * as S from "./styled";
import { TestGroupView } from "../TestViewer/TestList/testGroupView";
import { GptEmulatorInternalModal } from "./gptEmulatorInternal";
import { HistoryList } from "../uikit/gpt/History/historyList";
import { HistoryHolder } from "../uikit/gpt/History/types";
import { PromptVersion } from "../core/api/prompts/PromptVersion";
import { TestCase } from "../core/api/prompts/TestCase";
interface Props {}

export const PromptEditor: FC<Props> = observer(({}) => {
  const { account, prompts } = useStore();
  const { promptId } = useParams();
  const [prompt, setPrompt] = useState<Prompt | undefined>();
  const [selectedTestId, setSelectedTestIdInternal] = useState<string | undefined>();
  const [selectedTest, setSelectedTest] = useState<TestCase|undefined>();
  const [currentVersion, setCurrentVersion] = useState<PromptVersion | undefined>();
  const [loading, setLoading] = useState(true);

  const setSelectedTestId = useCallback((id: string) => {
    setSelectedTestIdInternal(id);
    const test = prompt?.getTestCase(id);
    setSelectedTest(test);
  }, [prompt]);

  useEffect(() => {
    prompts.loadPrompts(true);
  }, [account, prompts])

  const saveLocalChanges = useCallback(() => {
    currentVersion && account.setValue(`changes_prompt_${currentVersion?.promptId}`, currentVersion?.GetChanges());
    selectedTest && account.setValue(`changes_test_${selectedTest?.id}`, selectedTest?.GetUpdates() );
  }, [currentVersion, selectedTest]);
  
  React.useEffect(() => {
    const timeout = setInterval(saveLocalChanges, 5000)

    return () => { 
      clearInterval(timeout); 
      saveLocalChanges();
    }
  }, [currentVersion, selectedTest])

  useEffect(() => {
    setLoading(true);
    const fetch = async () => {
      if (promptId !== undefined) {
        const fetched = await prompts.getAsync(promptId);
        await fetched.LoadVersionsAsync();
        await fetched.LoadTestCasesAsync();
        setPrompt(fetched);
        
        const localPromptChanges = account.getValue<string>(`changes_prompt_${promptId}`);
        if (localPromptChanges !== null) {
          fetched.versions[0]?.ApplyChanges(localPromptChanges);
        }
        setCurrentVersion(fetched.versions[0]);
        for (var x of fetched.testCases) {
          var data = account.getValue<string>(`changes_test_${x.id}`);;
          if (data !== null && data !== undefined) {
            x.ApplyUpdates(data);
          }
        }
      }
    };
    fetch().finally(() => setLoading(false));
  }, [promptId, account, prompts]);


  if (loading) {
    return (
      <>
        <Icon name="spinner" style={{ width: 32 }} />
        <p>Loading Prompt</p>
      </>
    );
  }
  return (
    <>
      <S.Aside id="aside">
        <Link to={"/prompts"}>Back</Link>
        <TestGroupView
          prompt={prompt}
          indent={0}
          onSelect={(x) => setSelectedTestId(x)}
          selectedId={selectedTestId}
          onRun={(x, times) => {
            saveLocalChanges();
            prompt?.emulateAsync(currentVersion?.getData(selectedTest?.args), times, selectedTestId?[selectedTestId]:[]);
          }}
          onRunAll={(times) => {
            saveLocalChanges();
            prompt?.emulateAsync(currentVersion?.getData(selectedTest?.args), times, prompt.testCases.map(x=>x.id));
          }}
        ></TestGroupView>
      </S.Aside>
        {currentVersion && prompt && (
          <GptEmulatorInternalModal
            prompt={prompt}
            version={currentVersion}
            testCase={selectedTest}
            onVersionSelect={(x) => setCurrentVersion(x)}
          ></GptEmulatorInternalModal>
        )}
    </>
  );
});
