import styled from "styled-components";
import { PureButton } from "../uikit/Button";


export const Content = styled.div`
  box-sizing: border-box;
  padding: 24px 5%;
  margin: auto;
  overflow-y: auto;
  height: 100%;
  width: 100%;
`;

export const Aside = styled.div`
  background: #1b1b2d;
  overflow-y: scroll;
`;

export const Prompts = styled.div`
  display: grid;
  grid-template-columns: repeat(4, calc(25% - 24px));
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  padding-bottom: 80px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, calc(33% - 16px));
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(2, calc(50% - 16px));
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;


export const ActionMenu = styled(PureButton)`
  position: absolute;
  top: 20px;
  right: 16px;

  &::before {
    content: "";
    background: #1e1f33;
    filter: blur(5px);
    width: 30px;
    height: 20px;
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const PromptCard = styled.div`
  background: #1e1f33;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: 0.2s border;
  min-height: 240px;
  border: 1px solid transparent;
  position: relative;

  h2 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 28px;
    color: #eceff8;
    margin: 0;
  }

  ${ActionMenu} {
    transition: 0.2s opacity;
    opacity: 0;
  }

  &:hover {
    border-color: rgba(255, 255, 255, 0.3);
    ${ActionMenu} {
      opacity: 1;
    }
  }
`;

export const Description = styled.p`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #d9d9e2;
  margin: 0;
  margin-bottom: 12px;
`;

export const Time = styled.div`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.5);
  margin: 0;
  margin-bottom: 8px;

  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    flex-shrink: 0;
    margin-right: 8px;
  }
`;

export const Tags = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.06);
  padding-top: 12px;
  margin-top: auto;
  display: flex;
  gap: 8px;
`;


export const Workspace = styled.div`
  --gutter-size: 1px;

  display: grid;
  height: 100vh;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "editor"
    "results";

  #header {
    grid-area: header;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  }

  #editor {
    grid-area: editor;
  }

  #runner {
    grid-area: runner;
  }

  #tools {
    grid-area: tools;
    z-index: 1;
  }
`;


export const Panel = styled.div<{ isOpen }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;

  div {
    visibility: ${(p) => (p.isOpen ? "visible" : "hidden")};
  }
`;