import React, { FC, useCallback, useState } from "react";
import { Checkbox, Input, Table, TextArea, Message, Segment } from "semantic-ui-react";
import { ActionButton, ScrollbarStyle } from "../..";
import CopyButtonAction from "../../CopyButtonAction";
import "../index.css";
import { gptArgs } from "./types";
import { observer } from "mobx-react-lite";

interface Props {
  args: gptArgs;
}

export const GptArgsTable: FC<Props> = observer(({ args }) => {
  const getArgCell = (arg_name) => {
    return (
      <TextArea
        rows={Math.min(10, args.Args.get(arg_name)?.split("\n")?.length ?? 1)}
        placeholder={args.Args.get(arg_name)}
        className="dasha-textarea"
        onChange={(e, data) => args.Update(arg_name, data.value?.toString() ?? "")}
        value={args.Args.get(arg_name) ?? ""}
      ></TextArea>
    );
  };

  const getOptionRow = (arg_name) => {
    return (
      <Table.Row>
        <Table.Cell>
          <div>{arg_name}</div>
        </Table.Cell>
        <Table.Cell>{getArgCell(arg_name)}</Table.Cell>
        <Table.Cell>
          <ActionButton
            disabled={args.Args.get(arg_name) === args.InitialArgs.get(arg_name)}
            onClick={() => args.ResetArg(arg_name)}
          >
            Reset
          </ActionButton>
        </Table.Cell>
      </Table.Row>
    );
  };

  return (
    <Segment style={{overflowY: "auto", maxHeight: '70vh'}}>
      <ScrollbarStyle />
      <Message>
        Args can be placed in &#123;&#123;arg_name&#125;&#125; from conversation context or direct as args option
      </Message>
      <Table style={{ height: "80vh" }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1}>Name</Table.HeaderCell>
            <Table.HeaderCell>Value</Table.HeaderCell>
            <Table.HeaderCell width={1}>Clear</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{Array.from(args.Args.entries()).map(([k, v]) => getOptionRow(k))}</Table.Body>
      </Table>
      <CopyButtonAction tooltipText="Copy Args" clipboard={args.toDSLString()} hasIcon={false}>
        Copy Args
      </CopyButtonAction>
    </Segment>
  );
});
