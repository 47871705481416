import PlayerModel from "../../../RunnerPanel/Player/PlayerModel";
import { IDisposable } from "../../misc/emitter";
import { Trigger } from "./devlogs";
import MarkableMessage from "./MarkableMessage";

export interface SessionData {
  type: RunnerType;
  duration: number;
  jobId?: string;
  timeInitialized?: number;
  timeStarted?: number;
  timeEnded?: number;
  messages: Message[];
  isSending: boolean;
  isReadOnly?: boolean;
  audioLink?: string;
  outputData?: { [key: string]: string | number };
  inputData?: { [key: string]: string | number };
}

export enum LoadingStep {
  Deploying,
  Training,
  Activating,
  Connecting,
  Running
}

export interface SessionProtocol extends SessionData {
  uuid: string;
  audioPlayer?: PlayerModel | null;
  sandboxIframe?: HTMLIFrameElement;
  enableRender?: boolean;
  paymentRequired?: boolean;

  stop(): Promise<void>;
  send(message: string): Promise<void>;
  serialize(): SessionData;

  status: LoadingStep | null;
  currentMessageInAudio: number | string | null;
  selectCurrentMessageInAudio(id: string | number): void;
  selectedMessage: number | string | null;
  selectMessage(id: number | string | null): void;
  getUnrecognizedMessages(): MarkableMessage[];
  getEditedMessages(): MarkableMessage[];

  onDidDispose(cb: () => void): IDisposable;
  onDidSwitchNode(cb: (to: string | null) => void): IDisposable;
}

export interface Intent {
  value: string;
  label: string;
  probability: number;
  type: string;
}

export interface Transition {
  type: "goto" | "blockcall" | "return";
  from: string;
  to: string;
}

export interface Message {
  from: "ai" | "human";
  transitions?: Transition[];
  changeContext?: Record<string, number | string | boolean | null>;
  voiceSegmentId?: number;
  triggers?: Trigger[];
  message: string;
  thinking?: string | undefined;
  time: number;
  id: number | string;
  phraseSequenceId?: number;
  isSystem?: boolean;
  actions?: React.ReactNode;
}

export enum RunnerType {
  Text,
  Audio,
  Call,
  Test
}

export const RunnerTypeLocale = {
  [RunnerType.Audio]: "browser",
  [RunnerType.Call]: "phone",
  [RunnerType.Text]: "chat",
  [RunnerType.Test]: "test",
} as const;

export type OutputData = { [key: string]: any };
