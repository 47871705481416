import { PromptData, PromptVersionDTO } from "@dasha.ai/sdk/web/rest-api/generated/testsystem";
import { action, makeAutoObservable, observable } from "mobx";
import { gptOptions } from "../../../uikit/gpt/Options/types";
import { gptFunctionsStore } from "../../../uikit/gpt/Functions/types";
import { PromptAPI } from "./PromptStore";
import { gptArgs } from "../../../uikit/gpt/Args/types";

export class PromptVersion {
  id: string | undefined;
  public readonly promptId: string | undefined;
  public description: string;
  private data: PromptData;
  private api: PromptAPI | undefined;

  public gptOptions: gptOptions;
  public prompt: string;
  public initialPrompt: string;
  public functions: gptFunctionsStore;

  constructor(dto: PromptVersionDTO, api: PromptAPI | undefined) {
    this.id = dto.id;
    this.promptId = dto.promptId;
    this.description = dto.description ?? "";
    this.data = dto.data;
    this.api = api;
    this.gptOptions = new gptOptions(new Map<string, string | undefined>(Object.entries(dto.data.options ?? {})));
    this.functions = new gptFunctionsStore(Object.entries(dto.data.functionDescriptions ?? {}).map(([k, v]) => v!));
    this.prompt = dto.data.prompt;
    this.initialPrompt = dto.data.prompt;
    makeAutoObservable(this, 
      {
        prompt: observable,
        setPrompt: action
      }
    );
  }

  public setDescription(description: string) {
    this.description = description;
  }

  public setPrompt(prompt: string) {
    this.prompt = prompt;
  }

  public getData(args: gptArgs|undefined): PromptData {
    return {
      functionDescriptions: Object.fromEntries(this.functions.GetFunctionsDescription().entries()),
      prompt: this.prompt,
      options: Object.fromEntries(this.gptOptions.Options.entries()),
      promptArgs: Object.fromEntries(args?.Args?.entries()??Object.entries(this.data.promptArgs))
    }
  }

  public ApplyChanges(changes: string) {
    try {
      const data = JSON.parse(changes) as PromptData;
      for (var [k, fv] of Object.entries(data.functionDescriptions)) {
        this.functions.AddOrReplaceFunction(k, fv!);
      }
      this.gptOptions.Replace(new Map<string, string | undefined>(Array.from(Object.entries(data.options))));
      this.prompt = data.prompt;
    } catch {

    }
  }

  public GetChanges(): string {
    return JSON.stringify(this.getData(undefined));
  }
}
