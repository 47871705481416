import { makeAutoObservable } from "mobx";

export class gptOptions {
  public Options: Map<string, string | undefined>;
  public InitialOptions: Map<string, string | undefined>;
  constructor(options: Map<string, string | undefined>) {
    this.Options = new Map(options);
    this.InitialOptions = new Map(options);
    makeAutoObservable(this);
  }

  public ResetChanges() {
    this.Options = new Map(this.InitialOptions);
  }

  public ResetOption(argName: string) {
    if (this.InitialOptions.has(argName)) {
      this.Options.set(argName, this.InitialOptions.get(argName)!);
    } else {
      this.Options.delete(argName);
    }
    
  }

  public Replace(options: Map<string, string|undefined>) {
    this.Options = new Map(options);
  }
  
  public Update(argName: string, argValue: string) {
    if (argValue === "") {
      this.Options.delete(argName);
    } else {
      this.Options.set(argName, argValue);
    }
  }

  public Get(argName: string): string | undefined {
    return this.Options.get(argName);
  }
  
  public toDSLString() {
    var result: string[] = [];
    for (var [k, v] of this.Options.entries()) {
      result.push("\t" + k + ': "' + v + '"');
    }

    return "{\n" + result.join(",\n") + "\n}";
  }
}
