import * as uuid from "uuid";
import { action, computed, makeObservable, observable, runInAction, toJS } from "mobx";

import MarkableMessage from "./MarkableMessage";
import { Emitter } from "../../misc/emitter";
import { AppCommunicatorProtocol } from "../sandbox/types";
import { LoadingStep, Message, OutputData, RunnerType, SessionData, SessionProtocol } from "./types";
import { deepDiff, parseIntents } from "./utils";
import { DevLog } from "./devlogs";
import PlayerModel from "../../../RunnerPanel/Player/PlayerModel";
import { IAccount } from "@core/account/interface";

class SessionRunner implements SessionProtocol {
  @observable duration = 0;
  @observable timeInitialized?: number;
  @observable timeStarted?: number;
  @observable timeEnded?: number;
  @observable type: RunnerType;
  @observable isSending = false;
  @observable activeNode?: string;
  @observable jobId?: string;
  @observable selectedMessage: number | string | null = null;
  @observable status: LoadingStep | null = null;
  @observable currentMessageInAudio: number | string | null = null;
  @observable isReadOnly?: boolean | undefined;
  @observable audioPlayer: PlayerModel | null;
  @observable messages: (MarkableMessage | Message)[] = [];
  @observable outputData?: OutputData | undefined;
  @observable inputData?: OutputData | undefined;
  @observable sandboxIframe?: HTMLIFrameElement;
  @observable enableRender = false;
  @observable paymentRequired = false;

  public readonly uuid = uuid.v4();

  private rpc?: AppCommunicatorProtocol;
  private _sendedMessage: string | null = null;
  private timer: NodeJS.Timer;
  private devLogs: DevLog[] = [];
  private idCounter = 0;

  private readonly _onDidDispose = new Emitter<void>();
  public readonly onDidDispose = this._onDidDispose.event;

  private readonly _onDidSwitchNode = new Emitter<string | null>();
  public readonly onDidSwitchNode = this._onDidSwitchNode.event;

  private prevContext;
  private readonly _callRequests = new Map<string, any>();

  constructor(data: Partial<SessionData>, readonly account: IAccount) {
    makeObservable(this);

    this.jobId = data.jobId;
    this.timeInitialized = data.timeInitialized;
    this.timeStarted = data.timeStarted;
    this.timeEnded = data.timeEnded;
    this.messages = data.messages ?? [];
    this.duration = data.duration ?? 0;
    this.isSending = data.isSending ?? false;
    this.type = data.type ?? RunnerType.Text;
    this.isReadOnly = data.isReadOnly ?? false;
    this.outputData = data.outputData;
    this.inputData = data.inputData;

    this.messages = this.messages.map((m) => (m.from === "human" ? new MarkableMessage(m) : m));

    if (data.jobId && (this.type === RunnerType.Audio || this.type === RunnerType.Call)) {
      this.fetchAudio(data.jobId).catch((err) => console.error(err));
    }
  }

  @action
  bindCommunicator(rpc: AppCommunicatorProtocol): void {
    this.rpc = rpc;
    this.sandboxIframe = rpc.iframe;

    this.rpc.onDidSandboxEvent((event) => {
      runInAction(() => {
        if (event === "enableRender") this.enableRender = true;
        if (event === "disableRender") this.enableRender = false;
      });
    });

    this.rpc.onDidComplete(() =>
      runInAction(() => {
        this.timeEnded = Date.now();
        this.status = LoadingStep.Running;
        this._onDidDispose.fire();
        this._onDidSwitchNode.fire(null);
        clearInterval(this.timer);
      })
    );

    this.rpc.onDidChangeContext((context) => {
      runInAction(() => {
        if (this.prevContext) {
          const msg = this.messages[this.messages.length - 1];
          msg.changeContext = deepDiff(this.prevContext, context);
        }

        this.prevContext = context;
      });
    });

    this.rpc.onDidError(() =>
      runInAction(() => {
        this.timeEnded = Date.now();
        clearInterval(this.timer);
        this._onDidDispose.fire();
      })
    );

    this.rpc.onLogger(({ message }) =>
      runInAction(() => {
        if (this.status == null) {
          this.status = LoadingStep.Deploying;
        }

        if (message.includes("jobId:")) {
          const jobId = message.split(": ")[1];
          this.jobId = jobId;
        }

        if (message.includes("Register: Adding application")) {
          this.status = LoadingStep.Training;
        } else if (message.includes("Application prepared")) {
          this.status = LoadingStep.Activating;
        } else if (message.includes("Instance was activated ")) {
          this.status = LoadingStep.Connecting;
        } else if (message.includes("conversation started")) {
          this.status = LoadingStep.Running;
        }
      })
    );

    this.rpc.onDidDevLog((log: DevLog) =>
      runInAction(() => {
        if (!this.timeStarted) {
          this.timeStarted = Date.now();
          this.timer = setInterval(() => runInAction(() => this.duration++), 1000);
        }

        this.devLogs.push(log);
        if (log.msg.msgId === "StateSwitch") {
          if (log.msg.targetNodePath !== "checkChangeContext") {
            this._onDidSwitchNode.fire(log.msg.targetNodePath);
          }
        }
        if (log.msg.msgId === "LogMessage" && log.msg.level >= 40) {
          if (log.msg.message === "openai_apikey option is required")
          {
            this.paymentRequired = true;
          }
          this.messages.push({
            id: this.idCounter++,
            phraseSequenceId: 0,
            message: log.msg.message,
            time: new Date(log.time).valueOf(),
            changeContext: {},
            triggers: [],
            transitions: [],
            from: "ai",
            isSystem: true,
            thinking: undefined
          });
        }

        if (log.msg.msgId === "RawTextChannelMessage" && !log.incoming) {
          var thinking : string | undefined = undefined;
          if (log.msg.Metadata?.SourceName === "Thinking") {
            thinking = log.msg.Metadata?.SourceData;
          }          
          this.messages.push({
            id: this.idCounter++,
            phraseSequenceId: log.msg.phraseSequenceId,
            message: log.msg.text,
            time: +new Date(log.time),
            changeContext: {},
            triggers: [],
            transitions: [],
            from: "ai",
            thinking: thinking
          });
        }
        if (log.msg.msgId === "GptFunctionCallRequestMessage") {
          this._callRequests[log.msg.InstanceId + log.msg.FunctionName] = log.msg;
        }
        if (log.msg.msgId === "GptFunctionCallResponseMessage") {
          const request = this._callRequests[log.msg.InstanceId + log.msg.FunctionName];
          this.messages.push({
            id: this.idCounter++,
            phraseSequenceId: 0,
            message: `${log.msg.FunctionName}(${JSON.stringify(request?.Args)}) = ${JSON.stringify(
              log.msg.ReturnValue
            )}`,
            time: +new Date(log.time),
            changeContext: {},
            triggers: [],
            transitions: [],
            from: "ai",
            isSystem: true
          });   
        }

        if (log.msg.msgId === "JobCommunicationDebugMessage") {
          if (log.msg.content.type === "notification" && log.msg.content.method === "debugLog") {
            this.messages.push({
              id: this.idCounter++,
              phraseSequenceId: 0,
              message: log.msg.content.parameters.message,
              time: new Date(log.time).valueOf(),
              changeContext: {},
              triggers: [],
              transitions: [],
              from: "ai",
              isSystem: true,
              thinking: undefined,
            });
          }
        }

        if (log.msg.msgId === "JobCommunicationMessage") {
          if (log.msg.content.type === "request") {
            this.messages.push({
              id: this.idCounter++,
              phraseSequenceId: 0,
              message: `Start external call ${log.msg.content.method.replace("graphCall/", "")}(${JSON.stringify(
                log.msg.content.parameters
              )})`,
              time: new Date(log.time).valueOf(),
              changeContext: {},
              triggers: [],
              transitions: [],
              from: "ai",
              isSystem: true,
              thinking: undefined
            });
          }
          if (log.msg.content.type === "response") {
            this.messages.push({
              id: this.idCounter++,
              phraseSequenceId: 0,
              message: `Finish external call = ${JSON.stringify(log.msg.content.result)}`,
              time: new Date(log.time).valueOf(),
              changeContext: {},
              triggers: [],
              transitions: [],
              from: "ai",
              isSystem: true,
              thinking: undefined
            });
          }
          if (log.msg.content.type === "error") {
            this.messages.push({
              id: this.idCounter++,
              phraseSequenceId: 0,
              message: `Failed external call = ${log.msg.content.message}`,
              time: new Date(log.time).valueOf(),
              changeContext: {},
              triggers: [],
              transitions: [],
              from: "ai",
              isSystem: true,
              thinking: undefined
            });
          }
        }

        if (log.msg.msgId === "StoppedPlayingAudioChannelMessage") {
          const thinking = log.msg.Metadata?.SourceData ?? "";
          const messageRaw = this.messages.find(x=>x.isSystem === false && x.phraseSequenceId === (log.msg as any).phraseSequenceId) as Message;
          if(messageRaw !== undefined){
            messageRaw.message = log.msg.PlayedText ?? "";
            if (log.msg.Metadata?.SourceName === "Thinking" && thinking !== "") {
              messageRaw.thinking = thinking;
            }
          }
        }

        if (log.msg.msgId === "StateSwitch") {
          const stateSwitch = log.msg;
          if (stateSwitch.transitionType === "PreprocessorReturn") return;

          if (stateSwitch.message) {
            const msg = this.messages.find((msg) => msg.voiceSegmentId === stateSwitch.message?.voiceSegmentId);
            if (msg instanceof MarkableMessage) {
              msg.transitions.push({
                type: "goto",
                from: stateSwitch.sourceNodePath,
                to: stateSwitch.targetNodePath,
              });
            }
          } else {
            const variants = [stateSwitch.targetNodePath, stateSwitch.startingNodePath, stateSwitch.continueNodePath];
            const types = ["goto", "blockcall", "return"] as const;
            this.messages[this.messages.length - 1]?.transitions?.push({
              type: types[variants.findIndex((v) => v)] ?? "goto",
              from: stateSwitch.sourceNodePath || stateSwitch.callingNodePath || stateSwitch.returningNodePath,
              to: stateSwitch.targetNodePath || stateSwitch.startingNodePath || stateSwitch.continueNodePath,
            });
          }
        }

        let message: Message | undefined = undefined;
        if (log.msg.msgId === "RecognizedSpeechMessage" && log.incoming) {
          message = {
            id: this.idCounter++,
            triggers: parseIntents(log.msg),
            message: log.msg.results[0].text,
            voiceSegmentId: log.msg.voiceSegmentId,
            time: +new Date(log.time),
            changeContext: {},
            transitions: [],
            from: "human",
          };
        } else if (log.msg.msgId === "StateSwitch" && log.msg.message && log.incoming) {
          message = {
            id: this.idCounter++,
            triggers: parseIntents(log.msg.message),
            message: log.msg.message.results[0].text,
            voiceSegmentId: log.msg.message.voiceSegmentId,
            time: +new Date(log.time),
            changeContext: {},
            transitions: [],
            from: "human",
          };
        }
        if (message !== undefined) {
          if (this.isSending && this._sendedMessage === message.message) {
            this.isSending = false;
            this._sendedMessage = null;
          }
          let isReplaced = false;
          this.messages.map((msg) => {
            if (message === undefined) return msg;
            if (msg.voiceSegmentId !== message?.voiceSegmentId) return msg;
            if (msg instanceof MarkableMessage) {
              if (msg.transitions.length === 0) msg.deserialize(message);
              isReplaced = true;
            }
            return msg;
          });

          if (isReplaced === false) {
            this.messages.push(new MarkableMessage(message));
          }
        }
      })
    );
  }

  @action
  async fetchAudio(jobId: string) {
    try {
      const account = await this.account.connect();
      const audioLink = `https://${account.server}/api/v1/records/${jobId}`;
      const audioRes = await fetch(audioLink);
      const audioBlob = await audioRes.blob();
      const audioSrc = URL.createObjectURL(audioBlob);

      let player: PlayerModel | null = null;
      if (audioSrc) {
        player = new PlayerModel(audioSrc, audioLink, toJS(this.messages) || [], this);
      }

      runInAction(() => {
        this.audioPlayer = player;
      });
    } catch (err) {
      return;
    }
  }

  @action
  selectMessage(id: number | string) {
    this.selectedMessage = id;

    if (this.audioPlayer) {
      this.audioPlayer.setSelectedMessageId(id);
    }
  }

  @action
  selectCurrentMessageInAudio(id: number | string) {
    this.currentMessageInAudio = id;
  }

  getUnrecognizedMessages() {
    return this.messages.filter((msg): msg is MarkableMessage => {
      return msg instanceof MarkableMessage && msg.triggers.length === 0;
    });
  }

  getEditedMessages() {
    return this.messages.filter((msg): msg is MarkableMessage => {
      return msg instanceof MarkableMessage && msg.isMarked;
    });
  }

  @action
  async send(message: string): Promise<void> {
    if (this.isSending) return;
    this.isSending = true;
    this._sendedMessage = message;
    await this.rpc?.send(message);
  }

  @action
  async stop(): Promise<void> {
    if (this.timeEnded) return;
    this.timeEnded = Date.now();
    clearInterval(this.timer);
    await this.rpc?.dispose();
    this._onDidDispose.fire();
  }

  serialize(): SessionData {
    return {
      type: toJS(this.type),
      jobId: toJS(this.jobId),
      isSending: toJS(this.isSending),
      duration: toJS(this.duration),
      timeInitialized: toJS(this.timeInitialized),
      timeStarted: toJS(this.timeStarted),
      timeEnded: toJS(this.timeEnded),
      messages: toJS(this.messages.map((m) => (m instanceof MarkableMessage ? m.serialize() : m))),
    };
  }
}

export default SessionRunner;
