import { makeAutoObservable } from "mobx";

export class gptArgs {
  public Args: Map<string, string | undefined>;
  public InitialArgs: Map<string, string | undefined>;
  constructor(args: Map<string, string | undefined>) {
    this.Args = new Map(args);
    this.InitialArgs = new Map(args);
    makeAutoObservable(this);
  }

  public ResetChanges() {
    this.Args = new Map(this.InitialArgs);
  }

  public ResetArg(argName: string) {
    this.Args.set(argName, this.InitialArgs.get(argName));
  }

  public Update(argName: string, argValue: string) {
    if (argValue === "") {
      this.Args.delete(argName);
    } else {
      this.Args.set(argName, argValue);
    }
  }

  public Replace(args: Map<string, string | undefined>) {
    this.Args = new Map(args);
  }

  public toDSLString() {
    var result: string[] = [];
    for (var [k, v] of this.Args.entries()) {
      result.push("\t" + k + ': "' + v + '"');
    }

    return "{\n" + result.join(",\n") + "\n}";
  }

  public GetDiff(): Map<string, string> {
    return new Map<string, string>(Array.from(this.Args.entries()).filter(([k, v]) => this.InitialArgs.get(k) !== v));
  }
}
