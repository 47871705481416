import React, { FC, useEffect, useState, useRef, useCallback, useMemo } from "react";
import { observer } from "mobx-react-lite";

import { IAccount } from "@core/account/interface";
import { ActionButton } from "../uikit";
import { Button, Input, Loader, Message } from "semantic-ui-react";
import RestApi from "@core/account/api/fetch";
import CopyButton from "../uikit/CopyButton";

// Import WaveSurfer
import WaveSurfer from "wavesurfer.js";
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions";
import { Region } from "wavesurfer.js/dist/plugins/regions";
import Spectrogram from "wavesurfer.js/dist/plugins/spectrogram";
import "./index.css";
import Colormap from "colormap";
import { RangeSlider } from "../EditorPanel/LegacyGraphEditor/styled";
import SessionRunner from "../core/workspace/session-storage/SessionRunner";
import { RunnerType, SessionProtocol } from "../core/workspace/session-storage/types";
import DialogPanel from "../RunnerPanel/DialogPanel";
import GridLayout from "../core/misc/GridLayout";
import * as S from "./styled";
import { parseIntents } from "../core/workspace/session-storage/utils";
import { useNavigate, useParams } from "react-router-dom";
import Explorer from "@core/explorer/Explorer";
import * as dasha from "@dasha.ai/sdk/web";
import { GptEmulatorModal } from "./gptEmulator";

interface Props {
  account: IAccount;
  explorer: Explorer;
}

const extractId = (id: string) => {
  const m = id?.match(/(([0-9A-Fa-f]{8}[-]?[0-9A-Fa-f]{4}[-]?[0-9A-Fa-f]{4}[-]?[0-9A-Fa-f]{4}[-]?[0-9A-Fa-f]{12}))/);
  if (m === null || m === undefined) return undefined;
  return m[1];
};

const dashaApi = new RestApi();

const random = (min, max) => Math.random() * (max - min) + min;
const randomColor = () => `rgba(${random(0, 255)}, ${random(0, 255)}, ${random(0, 255)}, 0.5)`;

const InspectorPanel: FC<Props> = ({ account, explorer }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [devLog, setDevLog] = useState([]);
  const [inputId, setInputId] = useState(id);
  const [jobId, setJobId] = useState(id);
  const [audioUrl, setAudioUrl] = useState("");
  const [zoom, setZoom] = useState(10);
  const [dashaRegions, setDashaRegions] = useState([]);
  const containerRef = useRef();
  const [wavesurfer, setWavesurfer] = useState<WaveSurfer | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [activeRegion, setActiveRegion] = useState<SingleRegions | null>(null);
  const [sessionRunner, setSessionRunner] = useState<SessionRunner | undefined>(undefined);
  const [layout] = useState<GridLayout>(() => new GridLayout());
  const [error, setError] = useState<string | undefined>(undefined);
  const [transcriptionText, setTranscriptionText] = useState<string | undefined>(undefined);
  const [tuningText, setTuningText] = useState<string | undefined>(undefined);
  const [inputs, setInputs] = useState<any>(undefined);
  const [gptEmulatorOpened, setGptEmulatorOpened] = useState(false);
  const [gptEmulatorInstanceId, setGptEmulatorInstanceId] = useState<string | undefined>(undefined);
  const navigate = useNavigate();

  const onPlayClick = useCallback(() => {
    wavesurfer.isPlaying() ? wavesurfer.pause() : wavesurfer.play();
  }, [wavesurfer]);

  const onGptEmulate = useCallback(
    (instanceId) => {
      setGptEmulatorInstanceId(instanceId);
      setGptEmulatorOpened(true);
    },
    [devLog]
  );
  const processRegion = (region: Region) => {
    const defaultTop = region.channelIdx * (100 / 4);
    const defaultHeight = 100 / 4;

    let top = `${defaultTop}`;
    let height = `${defaultHeight}`;
    const currentStyle = region.element.getAttribute("style")!;
    let newStyle = currentStyle;
    if (region.content?.textContent?.includes("NR") || region.content?.textContent?.includes("FR")) {
      top = `${defaultTop - 4}`;
      height = `12`;
    } else if (region.content?.textContent?.includes("PotentialPause")) {
      top = `${defaultTop}`;
      height = `11`;
    } else {
      top = `${defaultTop + (region.channelIdx === 0 ? 5 : 2)}`;
      height = `12.5`;
    }
    newStyle += ` top: ${top}%; height: ${height}%;`;
    region.element.setAttribute("style", newStyle);
  };
  useEffect(() => {
    layout.bindGrid("#grid-workspace");
  }, [layout]);

  useEffect(() => {
    if (!wavesurfer) return;

    setCurrentTime(0);
    setIsPlaying(false);

    const subscriptions = [
      wavesurfer.on("play", () => setIsPlaying(true)),
      wavesurfer.on("pause", () => setIsPlaying(false)),
      wavesurfer.on("timeupdate", (currentTime) => setCurrentTime(currentTime)),
    ];

    return () => {
      subscriptions.forEach((unsub) => unsub());
    };
  }, [wavesurfer]);

  const createProjectFromJob = useCallback(() => {
    setError(undefined);
    if (id === undefined) {
      return;
    }
    setLoading(true);
    const fetchData = async () => {
      const fetch = await dashaApi.signFetch(account);
      const response = await (await fetch(`api/v1/logs/entity/${id}`)).json();
      const appId = response["applicationId"];
      const c = await account.connect();
      const packageContent = await dasha.applications.getApplicationPackageById(appId, { account: c });
      await explorer.createFromPackage(packageContent.content, `from job: ${id}`, inputs, sessionRunner);
    };

    fetchData()
      .catch((e) => setError("Failed to load:" + e.message))
      .finally(() => setLoading(false));
  }, [account, explorer, id, inputs]);
  useEffect(() => {
    setError(undefined);
    if (id === undefined) {
      return;
    }
    setLoading(true);
    const fetchData = async () => {
      const fetch = await dashaApi.signFetch(account);
      const response = await fetch(`api/v1/logs/debug/${jobId}`);
      const devlog = await response.json();
      const transcription = [];
      const voiceRegions = [];
      const speechRegions = [];
      const gptStartTimes = new Map<string, number>();
      const gptResponseTimes = new Map<string, number>();
      var outputData = undefined;
      var inputData = undefined;
      var refTime = undefined;
      const logMessages = [];
      var idCounter = 0;
      const visitedVoiceSegments = new Set<number>();
      const callRequests = new Map<string, any>();
      const promptResponses = new Map<string, string>();
      const promptRequests = new Map<string, string>();
      const tuningRequests = new Map<string, string>();
      const tuningPrompts = new Map<string, string>();
      const replaceRegions = new Map<number, Any>();
      const shownGptInstances = new Set<string>();
      const accountInternal = await account.connect();
      const usageRaw = await dasha.usage.getUsageForJob(jobId!, { account: accountInternal} );
      const usage = dasha.usage.aggregateUsage(usageRaw??[], new Set(["usageType", "usageSubType", "eventType"]));

      for (var message of devlog) {
        if (message.msg.msgId === "GptFunctionCallRequestMessage") {
          callRequests[message.msg.InstanceId + message.msg.FunctionName] = message.msg;
          speechRegions.push({
            start: (new Date(message.msg.StartTime) - refTime) / 1000,
            end: (new Date(message.msg.StartTime) - refTime) / 1000,
            content: message.msg.FunctionName,
            color: randomColor(),
            drag: false,
            resize: false,
            channelIdx: 1,
          });
        }
        if (message.msg.msgId === "JobCommunicationDebugMessage") {
          if (message.msg.content.type === "notification" && message.msg.content.method === "debugLog") {
            logMessages.push({
              id: idCounter++,
              phraseSequenceId: 0,
              message: message.msg.content.parameters.message,
              time: new Date(message.time),
              changeContext: {},
              triggers: [],
              transitions: [],
              from: "ai",
              isSystem: true,
              thinking: undefined,
            });
          }
        }
        if (message.msg.msgId === "JobCommunicationMessage") {
          if (message.msg.content.type === "request") {
            logMessages.push({
              id: idCounter++,
              phraseSequenceId: 0,
              message: `Start external call ${message.msg.content.method.replace("graphCall/", "")}(${JSON.stringify(
                message.msg.content.parameters
              )})`,
              time: new Date(message.time),
              changeContext: {},
              triggers: [],
              transitions: [],
              from: "ai",
              isSystem: true,
            });
          }
          if (message.msg.content.type === "response") {
            logMessages.push({
              id: idCounter++,
              phraseSequenceId: 0,
              message: `Finish external call = ${JSON.stringify(message.msg.content.result)}`,
              time: new Date(message.time),
              changeContext: {},
              triggers: [],
              transitions: [],
              from: "ai",
              isSystem: true,
              thinking: undefined,
            });
          }
          if (message.msg.content.type === "error") {
            logMessages.push({
              id: idCounter++,
              phraseSequenceId: 0,
              message: `Failed external call = ${message.msg.content.message}`,
              time: new Date(message.time),
              changeContext: {},
              triggers: [],
              transitions: [],
              from: "ai",
              isSystem: true,
              thinking: undefined,
            });
          }
        }

        if (message.msg.msgId === "LogMessage" && message.msg.level >= 40) {
          logMessages.push({
            id: idCounter++,
            phraseSequenceId: 0,
            message: message.msg.message,
            time: new Date(message.time),
            changeContext: {},
            triggers: [],
            transitions: [],
            from: "ai",
            isSystem: true,
            thinking: undefined,
          });
        }

        if (message.msg.msgId === "FailedOpenSessionChannelMessage") {
          logMessages.push({
            id: idCounter++,
            phraseSequenceId: 0,
            message: `${message.msg.reason} ${message.msg.details}`,
            time: new Date(message.time),
            changeContext: {},
            triggers: [],
            transitions: [],
            from: "ai",
            isSystem: true,
            thinking: undefined,
          });
        }

        if (message.msg.msgId === "GptFunctionCallResponseMessage") {
          const msg2 = callRequests[message.msg.InstanceId + message.msg.FunctionName];

          logMessages.push({
            id: idCounter++,
            phraseSequenceId: 0,
            message: `Start call ${message.msg.FunctionName}(${JSON.stringify(msg2.Args)})`,
            time: new Date(msg2.StartTime),
            changeContext: {},
            triggers: [],
            transitions: [],
            from: "ai",
            isSystem: true,
            thinking: undefined,
            gptInstanceId: shownGptInstances.has(message.msg.InstanceId) ? undefined: message.msg.InstanceId,
          });
          shownGptInstances.add(message.msg.InstanceId);
          logMessages.push({
            id: idCounter++,
            phraseSequenceId: 0,
            message: `Finish call ${message.msg.FunctionName}(${JSON.stringify(msg2.Args)}) = ${JSON.stringify(
              message.msg.ReturnValue
            )}`,
            time: new Date(message.msg.EndTime),
            changeContext: {},
            triggers: [],
            transitions: [],
            from: "ai",
            isSystem: true,
            thinking: undefined,
          });
          transcription.push({
            role: "assistant",
            function_call: { name: msg2.FunctionName, arguments: JSON.stringify(msg2.Args) },
          });
          transcription.push({
            role: "function",
            name: msg2.FunctionName,
            content: JSON.stringify(message.msg.ReturnValue),
          });
        }
        if (message.msg.msgId === "OpenedSessionChannelMessage" && refTime === undefined) {
          refTime = new Date(message.time);
        }

        if (
          message.msg.msgId === "StoppedPlayingAudioChannelMessage" &&
          message.msg.PlayedText !== null &&
          message.msg.PlayedText !== ""
        ) {
          var thinking: string | undefined = undefined;
          if (message.msg.Metadata?.SourceName === "Thinking") {
            thinking = message.msg.Metadata?.SourceData;
          }
          const startTime = (new Date(message.msg.StartPlayingTime) - refTime) / 1000;
          const endTime = (new Date(message.msg.EndPlayingTime) - refTime) / 1000;
          speechRegions.push({
            start: startTime,
            end: endTime,
            content: message.msg.PlayedText,
            color: randomColor(),
            drag: false,
            resize: false,
            channelIdx: 1,
            minLength: endTime - startTime,
            maxLength: endTime - startTime,
          });
          const gptInstanceId = shownGptInstances.has(promptResponses[message.msg.PlayedText]) ? undefined: promptResponses[message.msg.PlayedText];
          shownGptInstances.add(promptResponses[message.msg.PlayedText]);

          logMessages.push({
            id: idCounter++,
            phraseSequenceId: message.msg.phraseSequenceId,
            message: message.msg.PlayedText,
            time: +new Date(message.msg.StartPlayingTime),
            changeContext: {},
            triggers: [],
            transitions: [],
            from: "ai",
            gptInstanceId: gptInstanceId,
            thinking: thinking,
          });
          
          if (
            transcription.length > 0 &&
            transcription[transcription.length - 1].role === "assistant" &&
            transcription[transcription.length - 1].function_call === undefined
          ) {
            transcription[transcription.length - 1].content += " " + message.msg.PlayedText;
          } else {
            transcription.push({ role: "assistant", content: message.msg.PlayedText });
          }
        }
        if (message.msg.msgId === "GptStartMessage") {
          gptStartTimes[message.msg.InstanceId] = (new Date(message.time) - refTime) / 1000;
          promptRequests[message.msg.InstanceId] = message.msg.Prompt;
          const request = {
            messages: [{ role: "system", content: message.msg.Prompt }, ...transcription],
            functions: [],
          };
          for (const [fn, fv] of Object.entries(message.msg.Functions ?? {})) {
            request.functions.push({
              name: fv.Name,
              description: fv.Description,
              parameters: JSON.parse(fv.Parameters ?? "{}"),
            });
          }
          tuningRequests[message.msg.InstanceId] = request;
          tuningPrompts[message.msg.Prompt] = request;
        }
        if (message.msg.msgId === "GptResponseMessage") {
          promptResponses[message.msg.Message] = message.msg.InstanceId;
          if (tuningRequests[message.msg.InstanceId] !== undefined) {
            const r = tuningRequests[message.msg.InstanceId];
            if (
              r.messages[r.messages.length - 1].role === "assistant" &&
              r.messages[r.messages.length - 1].function_call === undefined
            ) {
              r.messages[r.messages.length - 1].content += " " + message.msg.Message;
            } else {
              r.messages.push({ role: "assistant", content: message.msg.Message });
            }
          }
          if (gptStartTimes[message.msg.InstanceId] !== undefined) {
            const startTime = gptStartTimes[message.msg.InstanceId];
            const endTime = (new Date(message.time) - refTime) / 1000;
            const duration = (endTime - startTime).toFixed(3);
            speechRegions.push({
              start: startTime,
              end: endTime,
              content: `FR ${duration}s`,
              color: randomColor(),
              drag: false,
              resize: false,
              channelIdx: 1,
              minLength: endTime - startTime,
              maxLength: endTime - startTime,
            });
            gptStartTimes[message.msg.InstanceId] = undefined;
          } else if (gptResponseTimes[message.msg.InstanceId] !== undefined && message.msg.Message !== "") {
            const startTime = (new Date(message.time) - refTime) / 1000;
            const endTime = (new Date(message.time) - refTime) / 1000;
            const duration = (endTime - gptResponseTimes[message.msg.InstanceId]).toFixed(3);
            speechRegions.push({
              start: startTime,
              end: endTime,
              content: `NR ${duration}s`,
              color: randomColor(),
              drag: false,
              resize: false,
              channelIdx: 1,
              minLength: endTime - startTime,
              maxLength: endTime - startTime,
            });
          }
          gptResponseTimes[message.msg.InstanceId] = (new Date(message.time) - refTime) / 1000;
        }

        if (message.msg.msgId === "SpeechChannelMessage" && message.msg.type === "PotentialPause") {
          speechRegions.push({
            start: (new Date(message.time) - refTime) / 1000,
            end: (new Date(message.time) - refTime) / 1000,
            content: "PotentialPause",
            color: randomColor(),
            drag: false,
            resize: false,
            channelIdx: 0,
          });
        }

        if (message.msg.msgId === "JobDataMessage") {
          outputData = message.msg.outputData;
          inputData = message.msg.inputData;
        }

        if (
          message.msg.msgId === "RawTextChannelMessage" &&
          (message.msg.textMsgId === "FinalTextChannelMessage" ||
            message.msg.textMsgId === "ConfidentTextChannelMessage")
        ) {
          const startTime = (new Date(message.msg.StartTime) - refTime) / 1000;
          const endTime = (new Date(message.msg.EndTime) - refTime) / 1000;
          if (message.msg.textMsgId === "FinalTextChannelMessage") {
            const region = {
              start: startTime,
              end: endTime,
              content: message.msg.text,
              color: randomColor(),
              drag: false,
              resize: false,
              channelIdx: 0,
              minLength: endTime - startTime,
              maxLength: endTime - startTime,
            };
            speechRegions.push(region);
            replaceRegions[message.msg.voiceSegmentId] = region;
          }

          if (message.msg.textMsgId === "ConfidentTextChannelMessage") {
            replaceRegions[message.msg.voiceSegmentId].content = message.msg.text;
          }

          if (message.msg.textMsgId === "FinalTextChannelMessage") {
            if (transcription.length > 0 && transcription[transcription.length - 1].role === "user") {
              transcription[transcription.length - 1].content += " " + message.msg.text;
            } else {
              transcription.push({ role: "user", content: message.msg.text });
            }
          }
        }
        if (
          message.msg.msgId === "RecognizedSpeechMessage" &&
          message.msg.__sourceMessageType__ === "FinalTextChannelMessage"
        ) {
          if (!visitedVoiceSegments.has(message.msg.voiceSegmentId)) {
            visitedVoiceSegments.add(message.msg.voiceSegmentId);
            logMessages.push({
              id: idCounter++,
              triggers: parseIntents(message.msg),
              message: message.msg.results[0].text,
              voiceSegmentId: message.msg.voiceSegmentId,
              time: +new Date(message.time),
              changeContext: {},
              transitions: [],
              from: "human",
            });
          }
        }

        if (message.msg.type === "dtmf") {
          logMessages.push({
            id: idCounter++,
            triggers: [],
            message: message.msg.message,
            voiceSegmentId: 0,
            time: +new Date(message.time),
            changeContext: {},
            transitions: [],
            from: "human",
          });
          const startTime = (new Date(message.time) - refTime) / 1000;
          const endTime = 0.3 + (new Date(message.time) - refTime) / 1000;
          const region = {
            start: startTime,
            end: endTime,
            content: message.msg.message,
            color: randomColor(),
            drag: false,
            resize: false,
            channelIdx: 0,
            minLength: endTime - startTime,
            maxLength: endTime - startTime,
          };
          speechRegions.push(region);
          if (transcription.length > 0 && transcription[transcription.length - 1].role === "user") {
            transcription[transcription.length - 1].content += " " + message.msg.message;
          } else {
            transcription.push({ role: "user", content: message.msg.message });
          }
        }
      }
      logMessages.sort((a, b) => a.time - b.time);
      for (var usageEntry of usage??[]) {
        if (usageEntry.usageType === "dasha.ai") {
          logMessages.push({
            id: idCounter++,
            phraseSequenceId: 0,
            message: `Total usage ${usageEntry.usageSubType} ${usageEntry.eventType} = ${usageEntry.billableUsage}`,
            time: new Date(message.msg.EndTime),
            changeContext: {},
            triggers: [],
            transitions: [],
            from: "ai",
            isSystem: true,
            thinking: undefined,
          });
        }

  
      }

      const runner = new SessionRunner(
        {
          type: RunnerType.Text,
          isSending: false,
          isReadOnly: true,
          inputData: inputData,
          outputData: outputData,
          messages: logMessages,
          jobId: jobId,
          timeStarted: +new Date(devlog[0].time),
          timeInitialized: +new Date(devlog[0].time),
          timeEnded: +new Date(devlog[devlog.length - 1].time),
        },
        account
      );
      setTuningText(
        Object.values(tuningPrompts)
          .map((x) => JSON.stringify(x).replaceAll("\n", ""))
          .join("\n")
      );
      setDashaRegions(speechRegions);
      setSessionRunner(runner);
      setDevLog(devlog);
      setInputs(inputData);
      setAudioUrl(`https://${account.server}/api/v1/records/${id}`);

      const trText = transcription
        .filter((x) => x.content !== undefined && x.role !== "function")
        .map((x) => `${x.role}: ${x.content?.replaceAll("\n", " ")?.replaceAll("\r", "")}`)
        .join("\n");
      setTranscriptionText(trText);

      layout.expandPanel("runner");
    };
    fetchData()
      .catch((e) => setError("Failed to load:" + e.message))
      .finally(() => setLoading(false));
  }, [id]);
  useEffect(() => {
    if (!containerRef.current || gptEmulatorOpened) return;
    const ws = WaveSurfer.create({
      container: containerRef.current,
      url: audioUrl,
      plugins: [],
      height: 200,
      splitChannels: [
        {
          waveColor: "rgb(200, 0, 200)",
          progressColor: "rgb(100, 0, 100)",
        },
        {
          waveColor: "rgb(0, 200, 200)",
          progressColor: "rgb(0, 100, 100)",
        },
      ],
    });
    const colormap = Colormap({ colormap: "jet", nshades: 256, format: "float", alpha: 1 });
    const spec = Spectrogram.create({
      labels: true,
      container: ws.getWrapper(),
      splitChannels: true,
      colorMap: colormap,
    });

    const wsRegions = ws.registerPlugin(RegionsPlugin.create());
    ws.registerPlugin(spec);
    ws.on("decode", () => {
      wsRegions.clearRegions();
      for (var x of dashaRegions) {
        wsRegions.addRegion(x);
      }
      wsRegions.getRegions().forEach((x) => processRegion(x));
      ws.zoom(zoom);
    });

    {
      wsRegions.on("region-in", (region) => {
        setActiveRegion(region);
      });
      wsRegions.on("region-out", (region) => {
        if (activeRegion === region) {
          region.play();
          setIsPlaying(true);
        }
      });
      wsRegions.on("region-clicked", (region, e) => {
        setActiveRegion(region);
      });
      // Reset the active region when the user clicks anywhere in the waveform
      ws.on("interaction", () => {
        setActiveRegion(null);
      });
    }

    setWavesurfer(ws);

    return () => {
      ws.destroy();
    };
  }, [containerRef, audioUrl, gptEmulatorOpened]);

  const loadCb = useCallback(() => {
    const id = extractId(inputId);
    if (id !== undefined) {
      navigate(`/inspector/${id}`);
    }
    setJobId(id);
  }, [inputId]);
  return (
    <S.Workspace id="grid-workspace">
      {sessionRunner && (
        <DialogPanel
          id="runner"
          session={sessionRunner}
          isOpen={layout.isExpanded("runner")}
          layout={layout}
          gptEmulateCallback={onGptEmulate}
        />
      )}
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          height: "90vh",
          width: "100%",
          overflowY: "scroll",
          overflowX: "scroll",
        }}
        id="editor"
      >
        {error && <Message negative>{error}</Message>}
        <Loader active={loading}>Loading</Loader>
        <Input
          label="JobId or record url"
          onChange={(e) => setInputId(e.target.value)}
          value={inputId}
          action={{
            color: "teal",
            labelPosition: "right",
            icon: "cloud download",
            content: "Load",
            onClick: loadCb,
            disabled: extractId(inputId) === undefined,
          }}
        ></Input>
        {audioUrl !== "" && (
          <>
            {gptEmulatorOpened && gptEmulatorInstanceId && (
              <>
                <ActionButton onClick={() => setGptEmulatorOpened(false)}> Close debugger </ActionButton>
                <GptEmulatorModal account={account} devLogs={devLog} gptInstanceId={gptEmulatorInstanceId} />
              </>
            )}
            {!gptEmulatorOpened && (
              <>
                <a
                  download={extractId(jobId) + ".json"}
                  href={URL.createObjectURL(new Blob([JSON.stringify(devLog)], { type: "application/json" }))}
                >
                  Download DevLog
                </a>

                <CopyButton tooltipText="Copy transcription" clipboard={transcriptionText || ""}>
                  Copy transcription
                </CopyButton>
                {tuningText && (
                  <CopyButton tooltipText="Copy tuning JSONL" clipboard={tuningText || ""}>
                    Copy tuning JSONL
                  </CopyButton>
                )}
                <ActionButton onClick={createProjectFromJob} style={{ marginTop: "1em" }}>
                  Create project in playground from this converstation
                </ActionButton>
                <ActionButton onClick={onPlayClick} style={{ marginTop: "1em" }}>
                  [{isPlaying ? "Pause" : "Play"}] Seconds played: {currentTime}
                </ActionButton>
                <RangeSlider>
                  <input
                    value={zoom}
                    onChange={(e) => {
                      if (!wavesurfer) return;

                      var number = Number.parseFloat(e.target.value);
                      setZoom(number);
                      wavesurfer.zoom(number);
                    }}
                    type="range"
                    min={10}
                    max={1000}
                    step={10}
                  />
                </RangeSlider>
                <div ref={containerRef} className="waveform"/>
              </>
            )}
          </>
        )}
      </div>
    </S.Workspace>
  );
};

export default observer(InspectorPanel);
