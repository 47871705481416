import React, { FC, useState } from "react";
import { observer } from "mobx-react-lite";
import { countBy, map, property } from "lodash";

import Workspace from "@core/workspace/Workspace";
import { ProjectVersion } from "@core/explorer/Project";
import UIManager from "@core/misc/UIManager";

import { PureButton, StrokeButton } from "../uikit/Button";
import { MenuOption, PopupMenu, Tooltip } from "../uikit/popup";
import { Icon } from "../uikit";

import RunConversations from "./RunConversations";
import * as S from "./styled";
import { AccountFeature } from "@core/account/interface";

interface Props {
  workspace: Workspace;
}


const VersionsPanel: FC<Props> = ({ workspace }) => {
  const project = workspace.project;
  const profiler = workspace.storages.profiler;

  if (project == null) return null;
  if (project.metadata.isEditable === false) return <p>No access</p>;

  type NestedVersion = ProjectVersion & { children: NestedVersion[] };
  const versions = new Map<string, NestedVersion>();
  Object.values(project.versions)
    .filter((v) => v)
    .forEach((v) => versions.set(v.name, { ...v, children: [] }));

  versions.forEach((version) => {
    if (version.from == null) return;
    versions.get(version.from)?.children.push(version);
  });

  const newVersion = async (from: string) => {
    await project.createVersion(from, Date.now().toString());
  };

  const renderVersion = (version: NestedVersion, depth = 0, name = "") => {
    const isSelected = project.version === version.name;
    const actions: MenuOption[] = [
      {
        label: "Fork version",
        action: () => newVersion(version.name),
        isEnable: true,
      },
      {
        isSelected: false,
        label: "Delete version",
        isEnable: Object.keys(project.versions).length > 1,
        action: async () => {
          await project.removeVersion(version.name);
        },
      },
    ];

    const getWidthOfInput = (value: string) => {
      const tmp = document.createElement("span");
      tmp.style.fontFamily = "var(--main-font)";
      tmp.style.border = "1px solid #ffffff4d";
      tmp.style.fontWeight = "400";
      tmp.style.padding = "0 8px";
      tmp.style.fontSize = "15px";

      tmp.textContent = value;
      document.body.appendChild(tmp);

      const theWidth = tmp.getBoundingClientRect().width;
      document.body.removeChild(tmp);
      return theWidth;
    };

    const handleInput = (e) => {
      e.target.style.width = getWidthOfInput(e.target.value) + "px";
    };

    return (
      <React.Fragment key={version.name}>
        <S.Version isSelected={isSelected} style={{ paddingLeft: 16 + depth }}>
          <div>
            <input
              maxLength={50}
              defaultValue={version.message || "Unnamed"}
              style={{ width: getWidthOfInput(version.message || "Unnamed") }}
              onBlur={(e) => project.changeMessage(version.name, e.target.value)}
              placeholder="Some version message"
              onInput={handleInput}
            />
          </div>

          <div>
            {project.version !== version.name && (
              <StrokeButton onClick={() => project.switchVersion(version.name)}>open</StrokeButton>
            )}

            <StrokeButton onClick={() => newVersion(version.name)}>fork</StrokeButton>

            <PopupMenu actions={actions} position={["bottom right", "top right"]} on="click" closeAfterSelect>
              <PureButton style={{ marginLeft: 8 }}>
                <Icon name="menu" />
              </PureButton>
            </PopupMenu>
          </div>
        </S.Version>
        {version.children.map((v, i) => renderVersion(v, depth + 16, [name, i + 1].filter((v) => v).join(".")))}
      </React.Fragment>
    );
  };

  return (
    <S.Container>
      {Array.from(versions.values())
        .filter((v) => v.from == null)
        .map((version) => renderVersion(version, 0))}
    </S.Container>
  );
};

export default observer(VersionsPanel);
