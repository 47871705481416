import { FunctionDescription } from "@dasha.ai/sdk/web/rest-api/generated/core";
import { makeAutoObservable } from "mobx";
import { objectToCamel } from "ts-case-convert";

export interface Parameter {
  name: string;
  required: boolean;
  type: string;
  description: string;
}

export class gptFunctionsStore {
    public Functions: gptFunction[];

    constructor(descriptions: FunctionDescription[]) {
        this.Functions = descriptions.map((x)=>new gptFunction(objectToCamel(x)));
        makeAutoObservable(this);
    }

    public AddOrReplaceFunction(name: string, description: FunctionDescription) {
      for (var i in this.Functions) {
        if (this.Functions[i].Name === name) {
          this.Functions[i] = new gptFunction(description);
          return;
        }
      }

      this.Functions.push(new gptFunction(description));
    }

    public Replace(description: Map<string, FunctionDescription>) {
      for (var [k, v] of description.entries()) {
        this.AddOrReplaceFunction(k, v);
      }
    }

    public GetByName(name: string): gptFunction|undefined {
      return this.Functions.find((x) => x.Name === name);
    }

    public GetFunctionsDescription(): Map<string, FunctionDescription> {
      const result = new Map<string, FunctionDescription>();
      for (var f of this.Functions) {
        result.set(f.Name, f.GetFunctionDescription());
      }
      return result;
    }

    public Reset() {
      for (var k of this.Functions) {
        k.ResetChanges();
      }
    }
}

export class gptFunction {
  public Name: string;
  public Description: string;
  public Returns: string;
  public Parameters: Parameter[];
  public Scheme: any;

  public InitialDescription: FunctionDescription;

  public setName(name: string) {
    this.Name = name;
  }

  public setDescription(description: string) {
    this.Description = description;
  }

  public setReturns(returns: string) {
    this.Returns = returns;
  }

  public updateParameter(idx: number, value: Parameter) {
    this.Parameters[idx] = value;
  }
  
  constructor(description: FunctionDescription) {
    this.InitialDescription = { ...description };
    makeAutoObservable(this);
    this.parse(description);
  }

  private parse(description: FunctionDescription) {
    const inititalDesc = description.description ?? "";
    const returnsConst = " returns: ";
    const index = inititalDesc.lastIndexOf(returnsConst);
    const params = JSON.parse(description.parameters ?? "{}");
    const required = new Set(params["required"]);

    this.Name = description.name ?? "";
    if (index >= 0) 
    {
      this.Description = inititalDesc.substring(0, index);
      this.Returns = inititalDesc.substring(index + returnsConst.length);
    } else {
      this.Description = inititalDesc;
      this.Returns = "";
    }

    this.Parameters = [];
    for (var [k,v] of Object.entries(params["properties"])) {
      const vTyped = v as {
        description: string;
        type: string;
      };
      this.Parameters.push({ ...{ required: required.has(k), name: k }, ...vTyped });
    }

    this.InitialDescription = description;
  }

  public ResetChanges() {
    this.parse(this.InitialDescription);
  }


  public GetJavaDoc() {
    var docs = "/**\n" + this.Description.replaceAll("\n", "\n*").trim() + "\n";
    for (var param of this.Parameters) {
      docs += "* @param " + param.name + " " + param.description.trim() + "\n";
    }
    docs += "* @return " + this.Returns.replaceAll("\n", " ").trim();
    docs += "\n*/";
    return docs;
  }

  public getJSONParams() {
    return {
        "type": "object",
        "required": this.Parameters.filter((x)=>x.required).map((x)=>x.name),
        "properties": Object.fromEntries(this.Parameters.map((param) => {
            return [
                param.name,
                {
                    type: param.type,
                    description: param.description
                }
            ];
        }))
    };  
  }

  public GetFunctionDescription(): FunctionDescription {
    return {
        name: this.Name,
        description: this.Description + " returns: " + this.Returns,
        parameters: JSON.stringify(this.getJSONParams()),
        blockPath: this.InitialDescription.blockPath,
        typeAlias: this.InitialDescription.typeAlias
    };
  }
}
