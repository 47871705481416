import { IHistoryMessage } from "@dasha.ai/sdk/web/gpt";
import React, { FC, useCallback, useState } from "react";
import { Button, Divider, List } from "semantic-ui-react";
import { HistoryItem } from "./historyItem";
import { HistoryHolder } from "./types";
import { observer } from "mobx-react-lite";
import { EditHistoryItemModal } from "./Editor/editHistoryItemModal";
import { gptFunctionsStore } from "../Functions/types";
import { Icon } from "../../Icon";

interface Props {
  history: HistoryHolder;
  prefix?: IHistoryMessage[];
  canEdit?: boolean;
  functions?: gptFunctionsStore;
}

export const HistoryList: FC<Props> = observer(({ history, prefix, canEdit, functions }) => {
  const [openEditor, setOpenEditor] = useState<IHistoryMessage | undefined>();
  const addButtons = (x: IHistoryMessage | undefined) => {
    if (!canEdit) {
      return (<></>);
    }

    if (x !== undefined && x.type === "Text" && x.thinking === undefined && x.text === "")
    {
      return (<></>);
    }
    return (
      <div>
        <Icon style={{ margin: "3px", cursor: "pointer", width: 32, height: 32 }} name="addtext"
          onClick={() =>
            setOpenEditor(history.CreateAfter(x, { type: "Text", text: "Text", source: 0, thinking: undefined }))
          }
        >
          Add text
        </Icon>
        {(functions?.Functions?.length ?? 0) > 0 && (
          <Icon style={{ margin: "3px", cursor: "pointer", width: 32, height: 32 }} name="addfunction"
            onClick={() =>
              setOpenEditor(
                history.CreateAfter(x, {
                  type: "call",
                  functionCallName: functions?.Functions[0]?.Name ?? "",
                  functionCallArgs: {},
                })
              )
            }
          >
            Add function call
          </Icon>
        )}
      </div>
    );
  };

  return (
    <div style={{ overflowY: "scroll", maxHeight: "60vh", display: "flex", flexDirection: "column-reverse" }}>
      <List>
        {prefix && (
          <>
            {prefix.map((x) => {
              return (
                <List.Item>
                  <HistoryItem item={x} />
                </List.Item>
              );
            })}
            <Divider />
          </>
        )}
        {addButtons(undefined)}
        {canEdit && <Divider/>}
        {history.Messages.map((x) => {
          return (
            <List.Item>
              <HistoryItem
                item={x}
                onEdit={
                  canEdit
                    ? (item) => {
                        setOpenEditor(item);
                      }
                    : undefined
                }
                onDelete={
                  canEdit
                    ? (item) => {
                        history.deleteMessage(item);
                      }
                    : undefined
                }
              />
              {canEdit && <Divider/>}
              {addButtons(x)}
            </List.Item>
          );
        })}
      </List>
      {openEditor && (
        <EditHistoryItemModal
          item={openEditor}
          opened={true}
          onClose={() => setOpenEditor(undefined)}
          onSave={(item) => {
            history.Replace(openEditor, item);
            setOpenEditor(undefined);
          }}
          functions={functions}
        />
      )}
    </div>
  );
});
