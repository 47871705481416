import { makeAutoObservable } from "mobx";

import AccountsManager from "./account/Manager";
import Explorer from "./explorer/Explorer";
import Project from "./explorer/Project";
import Workspace from "./workspace/Workspace";
import SIPClient from "./SIPClient";

import { IDisposable } from "./misc/emitter";
import { IAccount } from "./account/interface";
import SimpleTtsSpeaker from "./SimpleTtsSpeaker";
import { WorkerApi } from "./dsl/worker/api";
import { selectServers } from "./Selector";

class Playground {
  servers = selectServers();
  sip = new SIPClient(this.servers.sip);
  accounts = new AccountsManager(this.servers.app, this.servers.auth);
  explorer = new Explorer(this.accounts.account);
  workspace = new Workspace(this.sip);

  handleChangeProject?: IDisposable;
  handleRewrite?: IDisposable;

  constructor() {
    SimpleTtsSpeaker.initialize(this.accounts.account);
    makeAutoObservable(this);
    this.accounts.onDidAccount((a) => this.changeAccount(a));
  }

  async changeAccount(account: IAccount) {
    this.handleRewrite?.dispose();
    this.handleChangeProject?.dispose();

    this.explorer = new Explorer(account);
    SimpleTtsSpeaker.Instance.account = account;

    this.handleChangeProject = this.explorer.onDidProject(this.changeProject);
    this.workspace.setProject(null);
    await this.explorer.loadProjects();
  }

  changeProject = async (project: Project | null) => {
    if (project == null) this.workspace.setProject(null);

    SimpleTtsSpeaker.Instance.stop()
    void WorkerApi.Instance.removeAllModels();

    this.handleRewrite?.dispose();
    this.handleRewrite = project?.onDidRewrite(() => this.workspace.setProject(project));
  };
}

export default Playground;
