import React, { useState, ReactNode, useEffect, FC } from "react";

import { ActionsWrapper, Collapse, StyledFile, VerticalLine } from "./styled";

import { TestItemView } from "./testItemView";
import { Prompt, TestCase } from "@core/api/prompts/PromptStore";
import { observer } from "mobx-react-lite";
import { Button, Divider } from "semantic-ui-react";
import { Icon } from "../../uikit";

interface Props {
  prompt: Prompt;
  indent: number;
  selectedId: string | undefined;
  onSelect(id: string);
  onRun(id: string, times: number);
  onRunAll(times: number);
}

export const TestGroupView: FC<Props> = observer(({ prompt, indent, selectedId, onSelect, onRun, onRunAll }) => {
  const [loading, setLoading] = useState(false);

  return (
    <VerticalLine>
      {loading && <>Loading..</>}
      
      <StyledFile isSelected={false} style={{ paddingLeft: indent + 16 }}>
        <Icon name="run" onClick={()=>onRunAll(1)}/> <p>Run all</p>
      </StyledFile>

      <StyledFile isSelected={false} style={{ paddingLeft: indent + 16 }}>
        <Icon name="run" onClick={()=>onRunAll(3)}/> <p>Run all x3</p>
      </StyledFile>
      <Divider/>
      
      
      {prompt?.testCases?.map((x) => (
        <TestItemView
          key={x.id}
          item={x}
          prompt={prompt}
          isSelected={x.id === selectedId}
          onSelect={onSelect}
          onRun={onRun}
          indent={indent + 16}
        />
      ))}
    </VerticalLine>
  );
});
