import React, { FC, useCallback, useState } from "react";
import { Checkbox, Input, Table, TextArea, Message } from "semantic-ui-react";
import { ActionButton } from "../uikit";
import CopyButtonAction from "../uikit/CopyButtonAction";
import "./index.css";

interface Props {
  args: Record<string, string>;
  initialArgs: Record<string, string>;
  onUpdateArg: (arg_name: string, arg_value: string | undefined) => void;
}

export const GptArgsTable: FC<Props> = ({ args, initialArgs, onUpdateArg }) => {
  const getArgCell = (arg_name) => {
    return (
      <TextArea
        rows={Math.min(10, initialArgs[arg_name].split("\n").length)}
        placeholder={initialArgs[arg_name]}
        className="dasha-textarea"
        onChange={(e, data) => onUpdateArg(arg_name, data.value)}
        value={args[arg_name] ?? ""}
      ></TextArea>
    );
  };

  const getOptionRow = (arg_name) => {
    return (
      <Table.Row>
        <Table.Cell><div>{arg_name}</div></Table.Cell>
        <Table.Cell>{getArgCell(arg_name)}</Table.Cell>
        <Table.Cell>
          <ActionButton disabled={args[arg_name] === initialArgs[arg_name]} onClick={() => onUpdateArg(arg_name, initialArgs[arg_name])}>Reset</ActionButton>
        </Table.Cell>
      </Table.Row>
    );
  };
  const getArgsString = useCallback(() => {
    var result = [];
    for (var [k,v] of Object.entries(args)) {
      result.push("\t" + k + ": \"" + v + "\"");
    }
    
    return "{\n" + result.join(",\n") + "\n}";
  }, [args]);
  return (
    <>
      <Message>Args can be placed in  &#123;&#123;arg_name&#125;&#125; from conversation context or direct as args option</Message>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1}>Name</Table.HeaderCell>
            <Table.HeaderCell>Value</Table.HeaderCell>
            <Table.HeaderCell width={1}>Clear</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {Object.entries(args).map((x) => getOptionRow(x[0]))}
        </Table.Body>
      </Table>
      <CopyButtonAction tooltipText="Copy Args" clipboard={getArgsString} hasIcon={false}>
              Copy Args
      </CopyButtonAction>
    </>
  );
};
