import React, { FC, useEffect, useState } from "react";
import { PromptCard } from "./promptCard";
import * as S from "./styled";

import { TestPromptDTO } from "@dasha.ai/sdk/web/rest-api/generated/testsystem";
import { useStore } from "@core/api/GlobalStoreContext";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { Loader, Message } from "semantic-ui-react";

export const PromptsView: FC<{}> = observer(({}) => {
  const { prompts } = useStore();
  useEffect(() => {
    prompts.loadPrompts();
  }, [prompts]);
  return (
    <>
      <S.Content id="prompts">
        <S.Prompts>
          {<Loader active={prompts.loading} content={"Loading prompts"}></Loader>}
          {!prompts.loading && prompts.Prompts.length === 0 && <Message>Use inspector to add prompts and tests from converstations</Message>}
          {prompts.Prompts.map((prompt) => (
            <Link to={`/prompts/${prompt.id}`}>
              <PromptCard prompt={prompt}/>
            </Link>
          ))}
        </S.Prompts>
      </S.Content>
    </>
  );
});
